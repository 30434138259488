import React, { useState, useEffect } from "react";
import Styles from "./Test.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { SlEnvolopeLetter } from "react-icons/sl";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { SiAdobe } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa6";
import { SiPearson } from "react-icons/si";
import certificate from "./ImranKerimliCertificate.png";
import imran3x4 from "./imranKerimli.jpeg";


function Test() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    useEffect(() => {
        const newUrl = '/certificate/ImranKerimli/82932';
        if (window.location.hostname === 'www.aribilgi.com') {
            window.history.pushState(null, null, newUrl);
        }
    }, []);

    return (
        <>
            <div className={Styles.mainTestDiv}>
                <div className={Styles.mainTestHeader}>
                    <div>
                        <div><img src="https://www.aribilgi.com/wp-content/uploads/2020/09/cropped-logo-1.png.webp" alt="Logo" /></div>
                        <div className={menuOpen ? Styles.menuOpen : ''}>
                            <a href="https://www.aribilgi.com/">Ana Sayfa</a>
                            <a href="https://www.aribilgi.com/?gad_source=1&gbraid=0AAAAACK2TATZDgBcEtKacRORgV15pUbTc&gclid=CjwKCAjwupGyBhBBEiwA0UcqaGWnL3Axy8lFID5hR0JsbOdSlFiSU2FKZSMGTLmkB5KVjA5lhl5cxBoCD1gQAvD_BwE#">Eğitimler</a>
                            <a href="https://www.aribilgi.com/egitim-takvimi/">Eğitim Takvimi</a>
                            <a href="https://www.aribilgi.com/basari-hikayeleri/">Başarı Hikayeleri</a>
                            <a href="https://www.aribilgi.com/iletisim/">İletişim</a>
                        </div>
                        <div className={Styles.burgerIcon} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
                        </div>
                    </div>
                </div>
                <div className={Styles.mainTest2}>
                    <div className={Styles.card}>
                        <div className={Styles.card__img}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%">
                                <rect fill="#ffffff" width="540" height="450"></rect>
                                <defs>
                                    <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="0" x2="0" y1="0" y2="100%" gradientTransform="rotate(222,648,379)">
                                        <stop offset="0" stop-color="#ffffff"></stop>
                                        <stop offset="1" stop-color="rgb(31, 154, 198)"></stop>
                                    </linearGradient>
                                    <pattern patternUnits="userSpaceOnUse" id="b" width="300" height="250" x="0" y="0" viewBox="0 0 1080 900">
                                        <g fill-opacity="0.5">
                                            <polygon fill="#444" points="90 150 0 300 180 300"></polygon>
                                            <polygon points="90 150 180 0 0 0"></polygon>
                                            <polygon fill="#AAA" points="270 150 360 0 180 0"></polygon>
                                            <polygon fill="#DDD" points="450 150 360 300 540 300"></polygon>
                                            <polygon fill="#999" points="450 150 540 0 360 0"></polygon>
                                            <polygon points="630 150 540 300 720 300"></polygon>
                                            <polygon fill="#DDD" points="630 150 720 0 540 0"></polygon>
                                            <polygon fill="#444" points="810 150 720 300 900 300"></polygon>
                                            <polygon fill="#FFF" points="810 150 900 0 720 0"></polygon>
                                            <polygon fill="#DDD" points="990 150 900 300 1080 300"></polygon>
                                            <polygon fill="#444" points="990 150 1080 0 900 0"></polygon>
                                            <polygon fill="#DDD" points="90 450 0 600 180 600"></polygon>
                                            <polygon points="90 450 180 300 0 300"></polygon>
                                            <polygon fill="#666" points="270 450 180 600 360 600"></polygon>
                                            <polygon fill="#AAA" points="270 450 360 300 180 300"></polygon>
                                            <polygon fill="#DDD" points="450 450 360 600 540 600"></polygon>
                                            <polygon fill="#999" points="450 450 540 300 360 300"></polygon>
                                            <polygon fill="#999" points="630 450 540 600 720 600"></polygon>
                                            <polygon fill="#FFF" points="630 450 720 300 540 300"></polygon>
                                            <polygon points="810 450 720 600 900 600"></polygon>
                                            <polygon fill="#DDD" points="810 450 900 300 720 300"></polygon>
                                            <polygon fill="#AAA" points="990 450 900 600 1080 600"></polygon>
                                            <polygon fill="#444" points="990 450 1080 300 900 300"></polygon>
                                            <polygon fill="#222" points="90 750 0 900 180 900"></polygon>
                                            <polygon points="270 750 180 900 360 900"></polygon>
                                            <polygon fill="#DDD" points="270 750 360 600 180 600"></polygon>
                                            <polygon points="450 750 540 600 360 600"></polygon>
                                            <polygon points="630 750 540 900 720 900"></polygon>
                                            <polygon fill="#444" points="630 750 720 600 540 600"></polygon>
                                            <polygon fill="#AAA" points="810 750 720 900 900 900"></polygon>
                                            <polygon fill="#666" points="810 750 900 600 720 600"></polygon>
                                            <polygon fill="#999" points="990 750 900 900 1080 900"></polygon>
                                            <polygon fill="#999" points="180 0 90 150 270 150"></polygon>
                                            <polygon fill="#444" points="360 0 270 150 450 150"></polygon>
                                            <polygon fill="#FFF" points="540 0 450 150 630 150"></polygon>
                                            <polygon points="900 0 810 150 990 150"></polygon>
                                            <polygon fill="#222" points="0 300 -90 450 90 450"></polygon>
                                            <polygon fill="#FFF" points="0 300 90 150 -90 150"></polygon>
                                            <polygon fill="#FFF" points="180 300 90 450 270 450"></polygon>
                                            <polygon fill="#666" points="180 300 270 150 90 150"></polygon>
                                            <polygon fill="#222" points="360 300 270 450 450 450"></polygon>
                                            <polygon fill="#FFF" points="360 300 450 150 270 150"></polygon>
                                            <polygon fill="#444" points="540 300 450 450 630 450"></polygon>
                                            <polygon fill="#222" points="540 300 630 150 450 150"></polygon>
                                            <polygon fill="#AAA" points="720 300 630 450 810 450"></polygon>
                                            <polygon fill="#666" points="720 300 810 150 630 150"></polygon>
                                            <polygon fill="#FFF" points="900 300 810 450 990 450"></polygon>
                                            <polygon fill="#999" points="900 300 990 150 810 150"></polygon>
                                            <polygon points="0 600 -90 750 90 750"></polygon>
                                            <polygon fill="#666" points="0 600 90 450 -90 450"></polygon>
                                            <polygon fill="#AAA" points="180 600 90 750 270 750"></polygon>
                                            <polygon fill="#444" points="180 600 270 450 90 450"></polygon>
                                            <polygon fill="#444" points="360 600 270 750 450 750"></polygon>
                                            <polygon fill="#999" points="360 600 450 450 270 450"></polygon>
                                            <polygon fill="#666" points="540 600 630 450 450 450"></polygon>
                                            <polygon fill="#222" points="720 600 630 750 810 750"></polygon>
                                            <polygon fill="#FFF" points="900 600 810 750 990 750"></polygon>
                                            <polygon fill="#222" points="900 600 990 450 810 450"></polygon>
                                            <polygon fill="#DDD" points="0 900 90 750 -90 750"></polygon>
                                            <polygon fill="#444" points="180 900 270 750 90 750"></polygon>
                                            <polygon fill="#FFF" points="360 900 450 750 270 750"></polygon>
                                            <polygon fill="#AAA" points="540 900 630 750 450 750"></polygon>
                                            <polygon fill="#FFF" points="720 900 810 750 630 750"></polygon>
                                            <polygon fill="#222" points="900 900 990 750 810 750"></polygon>
                                            <polygon fill="#222" points="1080 300 990 450 1170 450"></polygon>
                                            <polygon fill="#FFF" points="1080 300 1170 150 990 150"></polygon>
                                            <polygon points="1080 600 990 750 1170 750"></polygon>
                                            <polygon fill="#666" points="1080 600 1170 450 990 450"></polygon>
                                            <polygon fill="#DDD" points="1080 900 1170 750 990 750"></polygon>
                                        </g>
                                    </pattern>
                                </defs>
                                <rect x="0" y="0" fill="url(#a)" width="100%" height="100%"></rect>
                                <rect x="0" y="0" fill="url(#b)" width="100%" height="100%"></rect>
                            </svg>
                        </div>
                        <div className={Styles.card__avatar}>
                            <img style={{ width: '100%', height: '100%', borderRadius: '100%', objectFit: "cover" }} src={imran3x4} />
                        </div>
                        <div className={Styles.card__title} >Imran Kerimli</div>
                        <div className={Styles.card__subtitle}>Architect - Memar</div>
                        <div className={Styles.card__wrapper} >
                            <button className={Styles.card__btn} >AutoCAD</button>
                            <button style={{ marginLeft: '5px' }} className={`${Styles.card__btn} ${Styles.card__btnSolid}`}>3D Max</button></div>
                    </div>
                </div>
                <div className={Styles.mainCenter}>
                    <img src={certificate} />
                </div>
                <div className={Styles.footerTest}>
                    <div className={Styles.footerColumns}>
                        <div>
                            <h4>Son Yazılar</h4>
                            <ul>
                                <li><a href="#">Veri Bilimi Nedir?</a></li>
                                <li><a href="#">Grafik Tasarım Kursu İstanbul</a></li>
                                <li><a href="#">Front End Developer Ne İş Yapar?</a></li>
                                <li><a href="#">Python Ne İş Yapar?</a></li>
                                <li><a href="#">Vray Render Alma</a></li>
                                <li><a href="#">Front End Developer Olmak İçin Nereden Başlanmalı</a></li>
                                <li><a href="#">Meslek Edindirme Kursları</a></li>
                                <li><a href="#">Geleceğin Meslekleri</a></li>
                                <li><a href="#">AutoCAD Nedir?</a></li>
                                <li><a href="#">Autocad Nasıl Öğrenilir?</a></li>
                                <li><a href="#">Çocuklar İçin Yazılım</a></li>
                                <li><a href="#">Photoshop Resim Boyutu Küçültme</a></li>
                                <li><a href="#">Online Grafik Tasarım Kursu</a></li>
                                <li><a href="#">Excel'de Formüllerin Başında Ne Kullanılır?</a></li>
                                <li><a href="#">En iyi Autocad Kursları</a></li>
                                <li><a href="#">Excel’de Sayfa Numarası Nasıl Eklenir?</a></li>
                                <li><a href="#">Excel’de Düşeyara İşlevi</a></li>
                            </ul>
                        </div>
                        <div>
                            <h4>İletişim</h4>
                            <ul>
                                <li><FaPhoneAlt color="#2ea5d6" /><span style={{ visibility: "hidden" }}>-</span> Kadıköy: <span style={{ visibility: "hidden" }}>---</span>(0216) 414 4 274</li>
                                <li><FaPhoneAlt color="#2ea5d6" /><span style={{ visibility: "hidden" }}>-</span> Pendik: <span style={{ visibility: "hidden" }}>---</span>(0216) 414 4 274</li>
                                <li><FaPhoneAlt color="#2ea5d6" /><span style={{ visibility: "hidden" }}>-</span> Beşiktaş: <span style={{ visibility: "hidden" }}>---</span>(0216) 258 4 274</li>
                                <li><FaPhoneAlt color="#2ea5d6" /><span style={{ visibility: "hidden" }}>-</span> Şirinevler: <span style={{ visibility: "hidden" }}>---</span>(0216) 451 4 274</li><br />
                                <li><a href="#"><FaWhatsapp color="#25d366" size={28} /><span style={{ visibility: "hidden" }}>-</span>WhatsApp: Tıklayınız</a></li>
                            </ul>
                        </div>
                        <div>
                            <h4>Eğitimler</h4>
                            <ul>
                                <li><a href="#">Yazılım Eğitimi</a></li>
                                <li><a href="#">Veri Bilimi Eğitimi</a></li>
                                <li><a href="#">Google Locker Studio Eğitimi</a></li>
                                <li><a href="#">React Native Eğitimi</a></li>
                                <li><a href="#">Grafik Tasarım - Video Effects Kurgu Eğitimi</a></li>
                                <li><a href="#">Java Eğitimi</a></li>
                                <li><a href="#">C++ Eğitimi</a></li>
                                <li><a href="#">Finansal Tabloların Analizi Eğitimi</a></li>
                                <li><a href="#">Bütçe ve Raporlama Eğitimi</a></li>
                                <li><a href="#">Primevera Eğitimi</a></li>
                                <li><a href="#">Yapay Zeka Eğitimi</a></li>
                                <li><a href="#">UX UI Eğitimi</a></li>

                            </ul>
                        </div>
                        <div>
                            <h4>Hakkımızda</h4>
                            <ul>
                                <li><a href="#">Eğitim Takvimi</a></li>
                                <li><a href="#">Test Merkezi</a></li>
                                <li><a href="#">Hakkımızda</a></li>
                                <li><a href="#">Öğrenci Çalışmaları</a></li>
                                <li><a href="#">Öğrenci Görüşleri</a></li>
                                <li><a href="#">Başarı Hikayeleri</a></li>
                                <li><a href="#">Bireysel Eğitimler</a></li>
                                <li><a href="#">Akademik Eğitimler</a></li>
                                <li><a href="#">İletişim</a></li>
                                <li><a href="#">KVKK ve Gizlilik Politikası</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className={Styles.footerBottom}>
                        <div className={Styles.socialIcons}>
                            <a href="#"><FaLinkedin size={24} color="white" /></a>
                            <a href="#"><FaInstagram size={24} color="white" /></a>
                            <a href="#"><SlEnvolopeLetter size={24} color="white" /></a>
                            <a href="#"><FaFacebookSquare size={24} color="white" /></a>
                            <a href="#"><FaYoutubeSquare size={24} color="white" /></a>
                        </div>
                        <div className={Styles.iconsMAcs}>
                            <div><img src="https://www.aribilgi.com/wp-content/uploads/2021/03/adobe-sonn.png.webp" alt="adobe" width="120px" height="30px" /></div>
                            <div><img src="https://www.aribilgi.com/wp-content/uploads/2021/03/google.png.webp" alt="google" width="120px" height="30px" /></div>
                            <div><img src="https://www.aribilgi.com/wp-content/uploads/2021/03/microsoft.png.webp" alt="microsoft" width="120px" height="30px" /></div>
                            <div><img src="https://www.aribilgi.com/wp-content/uploads/2021/03/person.png.webp" alt="pearson-vue" width="120px" height="30px" /></div></div>
                        <div className={Styles.fEnd}>
                            <p>2024 © Ara Bilgi. Tüm Hakları Saklıdır.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Test;


