import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Article from "./pages/article";
import AboutUs from "./pages/aboutus";
import PrivacyPolicy from "./pages/privacypolicy";
import AdminLogin from "./pages/adminlogin";
import AdminPage from "./pages/adminpage";
import NotFoundPage from "./pages/notfound";
import { Provider, useSelector } from "react-redux"; // Importing useSelector here
import store from "./Redux/Store";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Import BrowserRouter and Routes
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminAllBlogs from "./pages/AdminAllBlogs";
import LiveBlogDetails from "./components/Admin/LiveBlogDetails";
import Test from "./components/Test/Test";

const queryClient = new QueryClient();

// Creating a component to wrap routing logic
function AppRouter() {
  const { token } = useSelector((x) => x.auth);
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path={`article/:id`} element={<Article />} />
      <Route path="aboutus" element={<AboutUs />} />
      <Route path="privacypolicy" element={<PrivacyPolicy />} />
      <Route path="www.aribilgi.com/certificate/ImranKerimli/82932" element={<Test />} />
      <Route
        path="AdminAllBlogs"
        element={token ? <AdminAllBlogs /> : <NotFoundPage />}
      />
      <Route
        path="8bb88bc3f23f487bb3bebc294bec6aa9/adminlogin"
        element={token === null ? <AdminLogin /> : <App />}
      />
      <Route
        path="adminpage"
        element={token ? <AdminPage /> : <NotFoundPage />}
      />
      <Route path="LiveBlogDetails" element={token ? <LiveBlogDetails /> : <NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);
