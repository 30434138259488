import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AdminAllBlog.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  DeleteByBlog,
  TotalPageCount,
  GetAllBlogsSearch,
} from "../Service/BlogService";
import Modal from "react-bootstrap/Modal";
import { IoClose } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Button from "react-bootstrap/Button";
import Navbar from "../components/Navbar/navbar";
import ClipLoader from "react-spinners/ClipLoader";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";

const AdminAllBlogs = () => {
  const notifySuccess = (value) => {
    toast.success(`The ${value} has been deleted`, {});
  };

  const notifyWarring = () => {
    toast.warning(`Please fill the inputs!`, {});
  };

  const queryClient = useQueryClient();

  const { token } = useSelector((x) => x.auth);
  const decodedToken = token ? jwtDecode(token) : null;
  const dispacth = useDispatch();
  //change
  const adminId = decodedToken
    ? decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ]
    : null;

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const onSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    queryClient.invalidateQueries(["GetAllBlog"]);
  };

  const { data: GetAllBlog } = useQuery(
    ["GetAllBlog", searchTerm, currentPage],
    () => GetAllBlogsSearch(searchTerm, currentPage)
  );

  const { data: BlogPageSize } = useQuery(["BlogPageSize"], () =>
    TotalPageCount()
  );

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < BlogPageSize?.data) {
      onPageChange(currentPage + 1);
    }
  };
  const clickSearchBtn = () => {
    queryClient.invalidateQueries(["GetAllBlog"]);
  };

  const [modalShow, setModalShow] = useState(false);
  const [blogId, setBlogId] = useState(null);

  const onClickDeletBtn = (blogId) => {
    setBlogId(blogId);
    setModalShow((pres) => !pres);
  };

  useEffect(() => {
    if (modalShow === false) {
      setBlogId(null);
    }
  }, [modalShow]);

  const { mutate, isLoading } = useMutation(
    ({ blogId, adminId }) => DeleteByBlog(blogId, adminId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["GetAllBlog"]);
        setModalShow(false);
        notifySuccess("Blog");
      },
      onError: (error) => {},
    }
  );

  return (
    <div>
      <Navbar />
      <Container className="AdminAllBlogContainer">
        <div className="AdminAllBlog_Header">
          <h1>Blogs</h1>
          <button onClick={() => navigate("/adminpage")}>Create Blog</button>
        </div>
        <div>
          <InputGroup className="mb-3 mt-3">
            <Form.Control
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Seach blog"
            />
            <Button
              onClick={() => clickSearchBtn()}
              variant="outline-primary"
              id="button-addon2"
            >
              Search
            </Button>
          </InputGroup>
        </div>
        {GetAllBlog?.data !== null &&
          GetAllBlog?.data.map((blog, index) => {
            return (
              <Row key={index} className="AdminAllBlogRow">
                <Col className="AdminAllBlogRow_Index" xs lg="1">
                  {index + 1}
                </Col>
                <Col className="AdminAllBlogRow_FirstCol" xs lg="4">
                  <img src={blog.mainImageUrl} />
                </Col>
                <Col className="AdminAllBlogRow_SeccondCol" xs lg="5">
                  <div>
                    <span style={{ color: "blue" }}>Title:</span> {blog.title}
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "blue" }}>Blog Author Name:</span>{" "}
                    {blog.blogAuthorName}
                  </div>
                </Col>
                <Col className="AdminAllBlogRow_Details" xs lg="1">
                  <button onClick={() => navigate(`/article/${blog.id}`)}>
                    Details
                  </button>
                </Col>
                <Col className="AdminAllBlogRow_Delete" xs lg="1">
                  <button onClick={() => onClickDeletBtn(blog.id)}>
                    Delete
                  </button>
                </Col>
              </Row>
            );
          })}
        <ToastContainer />
        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
          fullscreen="md-down"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            // style={{ backgroundColor: isLoading === true && "transparent" }}
            className="DeleteModalShow"
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IoClose size={20} onClick={() => setModalShow(false)} />
            </div>
            <div className="DeleteModalShow_center">
              {isLoading === false ? (
                <>
                  <IoIosCloseCircleOutline size={100} color="#f15e5e" />
                  <h3>Are you sure?</h3>
                  <p>
                    Do you really want to delete these records? This process
                    cannot be undone.
                  </p>
                  <div>
                    <Button
                      variant="secondary"
                      onClick={() => setModalShow(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => mutate({ blogId, adminId })}
                    >
                      Delete
                    </Button>
                  </div>
                </>
              ) : (
                <ClipLoader
                  color="#f15e5e"
                  loading={true}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <div
        style={{
          marginBottom: "60px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          display: BlogPageSize?.data === 1 ? "none" : "flex",
        }}
      >
        <Button
          style={{
            backgroundColor: "transparent",
            border: "none",
            visibility: currentPage === 1 && "hidden",
          }}
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          <GrFormPrevious size={26} color="gray" />
        </Button>
        {BlogPageSize?.data !== null &&
          [...Array(BlogPageSize?.data).keys()].map((number) => {
            return (
              <Button
                key={number}
                onClick={() => onPageChange(number + 1)}
                style={{
                  backgroundColor: "black",
                  color: currentPage === number + 1 ? "white" : "white",
                  border: `2px solid ${
                    currentPage === number + 1 ? "#e0e0e6" : "gray"
                  }`,
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {number + 1}
              </Button>
            );
          })}
        <Button
          onClick={handleNext}
          style={{
            backgroundColor: "transparent",
            border: "none",
            visibility: currentPage === BlogPageSize?.data && "hidden",
          }}
          disabled={currentPage === BlogPageSize?.data}
        >
          <GrFormNext size={26} color="gray" />
        </Button>
      </div>
    </div>
  );
};

export default AdminAllBlogs;
