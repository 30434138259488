import React from "react";
import Styles from "./teamMembers.module.css";
import DefaultPersonImage from "./DefaultPersonImage.jpg";
import { FiLink2 } from "react-icons/fi";



function TeamSection2({ data }) {

    function formatName(fullname) {
        return fullname.length > 12 ? `${fullname.slice(0, 10)}...` : fullname;
    }

    return (
        <div className={Styles.teamSection} id={Styles.teamSection}>
            <div className={Styles.teamHeader}>Contributors and Authors</div>
            {data !== null &&
                data?.map((member, index) => (
                    <div style={{ backgroundColor: "white" }} key={index} className={Styles.teamMember}>
                        <img
                            src={
                                member.profilImageUrl !== null
                                    ? member.profilImageUrl
                                    : DefaultPersonImage
                            }
                            alt={member.profilImageUrl}
                        />
                        <div className={Styles.teamMemberInfo}>
                            <h3 style={{ color: "black", marginTop: "15px" }}>
                                {formatName(member.fullname)}
                                <a
                                    href={member.contactLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <a style={{ visibility: "hidden" }}>-</a>
                                    <FiLink2 color={"black"} />
                                </a>
                            </h3>
                            <p style={{ color: "black" }}>{member.position}</p>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default TeamSection2;
