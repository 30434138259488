import React, { useEffect, useRef, useState } from "react";
import "./LiveBlogDetails.css";
import Navbar from "../Navbar/navbar";
import CardsForArticle from "../cards/cards-for-article-page";
import Footer from "../Footer/footer";
import TeamMembers from "../Team-members/team-member";
import Cookie from "../Cookies/cookie";
import BoundmaxNotDetecdet from "./BoundmaxNotDetecdet.webp";
import TeamSection2 from "../Team-members/TeamSection2";


function LiveBlogDetails({ title }) {
    const titleRef = useRef(null);

    useEffect(() => {
        if (titleRef.current) {
            document.title = `Boundmax - ${titleRef.current.textContent}`;
        }
    }, []);

    const toFormatDate = (rawDate) => {
        const date = new Date(rawDate);

        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const readOnlyModules = {
        toolbar: false,
    };

    const [isCopy, setIsCopy] = useState(false);
    const useParamsUrl = () => {
        const currentUrl = window.location.href;

        navigator.clipboard
            .writeText(currentUrl)
            .then(() => {
                console.log("URL copied to clipboard:", currentUrl);
            })
            .catch((error) => {
                console.error("Error copying URL to clipboard:", error);
            });
        setIsCopy(true);
    };

    const addIdToImages = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const images = doc.querySelectorAll("img");
        const h1Tag = doc.querySelectorAll("h1");
        const h2Tag = doc.querySelectorAll("h2");
        const spanTag = doc.querySelectorAll("*");

        images.forEach((img, index) => {
            if (!img.hasAttribute("id")) {
                img.setAttribute("id", `secimg2`);
            }
        });

        h1Tag.forEach((h1, index) => {
            if (!h1.hasAttribute("id")) {
                h1.setAttribute("id", `hTextEditor`);
            }
        });

        h2Tag.forEach((h2, index) => {
            if (!h2.hasAttribute("id")) {
                h2.setAttribute("id", `hTextEditor`);
            }
        });

        spanTag.forEach((span, index) => {
            if (!span.hasAttribute("id")) {
                span.setAttribute("id", `DarkModeP`);
            }
        });

        return doc.body.innerHTML;
    };


    const [blogTitle, setBlogTitle] = useState('');
    const [blogMainImage, setBlogMainImage] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [blogText, setBlogText] = useState('');
    const [GetReferenceDtos, setGetReferenceDtos] = useState([]);
    const [authors, setAuthors] = useState([]);



    const sanitizedHTML = addIdToImages(blogText);


    useEffect(() => {
        setBlogTitle(localStorage.getItem('blogTitle') || '');
        setBlogMainImage(localStorage.getItem('blogMainImage') || '');
        setSelectedLanguage(localStorage.getItem('selectedLanguage') || '');
        setBlogText(localStorage.getItem('blogText') || '');
        setGetReferenceDtos(JSON.parse(localStorage.getItem('GetReferenceDtos')) || '');
        setAuthors(JSON.parse(localStorage.getItem('authors')) || '');

        const handleStorageChange = () => {
            setBlogTitle(localStorage.getItem('blogTitle') || '');
            setBlogMainImage(localStorage.getItem('blogMainImage') || '');
            setSelectedLanguage(localStorage.getItem('selectedLanguage') || '');
            setBlogText(localStorage.getItem('blogText') || '');
            setGetReferenceDtos(JSON.parse(localStorage.getItem('GetReferenceDtos')) || '');
            setAuthors(JSON.parse(localStorage.getItem('authors')) || '');
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    return (
        <div>
            <Navbar />
            <Cookie />
            <header id="headerSection">
                <h1 className="article" ref={titleRef}>
                    {blogTitle !== "" ? blogTitle : "Not determined"}
                </h1>
                <div className="info-icons">
                    <p>
                        <i className="uil uil-schedule"></i>
                        {toFormatDate(Date.now())}
                    </p>
                    <p>
                        <i className="uil uil-english-to-chinese"></i>Source Language:{" "}
                        {selectedLanguage !== "" ? selectedLanguage : "Not determined"}
                    </p>
                </div>
                <img id="headerimg" src={blogMainImage !== "" ? blogMainImage : BoundmaxNotDetecdet} alt="Header" />
                <div className="clear"></div>
            </header>

            <section className="content" id="articleSection">
                <div
                    id="setTextData"
                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                />
                <h4
                    style={{
                        display: GetReferenceDtos?.length === 0 && "none",
                    }}
                >
                    References:
                </h4>
                <div>
                    {GetReferenceDtos?.length > 0 &&
                        GetReferenceDtos.map((reference, index) => {
                            return (
                                <p id="refernceslinks">
                                    <a target="_new" href={reference.ReferenceLink}>
                                        {reference.ReferenceLink}
                                    </a>
                                </p>
                            );
                        })}
                </div>
            </section>
            <div
            // style={{
            //     display: GetByBlog?.data?.getAuthorDtos?.length === 0 && "none",
            // }}
            >
                <TeamSection2 data={authors} />
            </div>
            <button onClick={useParamsUrl} className="copy-link-btn">
                <i className="uil uil-copy"></i> {isCopy ? "Copied" : "Copy Link"}
            </button>

            <div className="line3">
                <div className="circle3">
                    <img src={BoundmaxNotDetecdet} alt="Icon" />
                </div>
            </div>
            <CardsForArticle />
            <Footer />
        </div>
    );
}

export default LiveBlogDetails;
