import React from "react";
import "./footer.css";
import favico from "../../Images/Bm.jpg";

function Footer() {
  return (
    <>
      <div className="line">
        <div className="circle">
          <img src={favico} alt="Icon" />
        </div>
      </div>

      <footer id="footer">
        <div className="quick-links">
          <ul>
            <li>
              <a href="/privacypolicy">Privacy & Policy</a>
            </li>
            <li>
              <a href="/">Boundmax v1.0</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
          </ul>
        </div>
        <div className="all-rights-reserved">
          <p>© 2024 Boundmax All Rights Reserved</p>
          <p id="inspired">
            Inspired by&nbsp;
            <a target="_blank" href="https://theinvestor.ge/">
              THEINVESTOR
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
