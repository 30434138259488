import React, { useEffect, useRef, useState } from "react";
import "../../src/Assets/Styles/article.css";
import Navbar from "../components/Navbar/navbar";
import CardsForArticle from "../components/cards/cards-for-article-page";
import Footer from "../components/Footer/footer";
import TeamMembers from "../components/Team-members/team-member";
import Cookie from "../components/Cookies/cookie";
import { GetByBlogId } from "../Service/BlogService";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import favico from "../Images/Bm.jpg";
import Loading from "./Loading";
import BlogDetailsLoading from "../components/Loading/BlogDetailsLoading";

function Article({ title }) {
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      document.title = `Boundmax - ${titleRef.current.textContent}`;
    }
  }, []);

  const { id } = useParams();

  const { data: GetByBlog } = useQuery(["GetAllBlog", id], () =>
    GetByBlogId(id)
  );


  const toFormatDate = (rawDate) => {
    const date = new Date(rawDate);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const [isCopy, setIsCopy] = useState(false);
  const useParamsUrl = () => {
    const currentUrl = window.location.href;

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        console.log("URL copied to clipboard:", currentUrl);
      })
      .catch((error) => {
        console.error("Error copying URL to clipboard:", error);
      });
    setIsCopy(true);
  };

  const addIdToImages = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.querySelectorAll("img");
    const h1Tag = doc.querySelectorAll("h1");
    const h2Tag = doc.querySelectorAll("h2");
    const spanTag = doc.querySelectorAll("*");

    images.forEach((img, index) => {
      if (!img.hasAttribute("id")) {
        img.setAttribute("id", `secimg2`);
      }
    });

    h1Tag.forEach((h1, index) => {
      if (!h1.hasAttribute("id")) {
        h1.setAttribute("id", `hTextEditor`);
      }
    });

    h2Tag.forEach((h2, index) => {
      if (!h2.hasAttribute("id")) {
        h2.setAttribute("id", `hTextEditor`);
      }
    });

    spanTag.forEach((span, index) => {
      if (!span.hasAttribute("id")) {
        span.setAttribute("id", `DarkModeP`);
      }
    });

    return doc.body.innerHTML;
  };
  const sanitizedHTML = addIdToImages(GetByBlog?.data?.text);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    if (GetByBlog?.data?.title) {
      document.title = "Boundmax - " + GetByBlog.data.title;
    } else {
      document.title = "Boundmax";
    }
    return () => {
      document.title = "Boundmax";
    };
  }, [GetByBlog?.data?.title]);


  const getDomainName = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (error) {
      console.error('Invalid URL:', url);
      return 'Invalid URL';
    }
  };

  return (
    <>
      {isLoading || GetByBlog?.data?.text === undefined ? (
        <BlogDetailsLoading />
      ) : (
        <div>
          <Cookie />
          <Navbar />
          <header id="headerSection">
            <h1 className="article" ref={titleRef}>
              {GetByBlog?.data?.title}
            </h1>
            <div className="info-icons">
              <p>
                <i className="uil uil-schedule"></i>
                {toFormatDate(GetByBlog?.data?.createdDate)}
              </p>
              <p>
                <i className="uil uil-english-to-chinese"></i>Source Language:{" "}
                {GetByBlog?.data?.sourceLanguage}
              </p>
            </div>
            <img id="headerimg" src={GetByBlog?.data?.mainImageUrl} alt="Header" />
            <div className="clear"></div>
          </header>

          <section className="content" id="articleSection">
            <div
              id="setTextData"
              dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            />
            <h4
              style={{
                display: GetByBlog?.data?.getReferenceDtos?.length === 0 && "none",
              }}
            >
              References:
            </h4>
            <div>
              {GetByBlog?.data?.getReferenceDtos?.length > 0 &&
                GetByBlog?.data?.getReferenceDtos.map((reference, index) => {
                  return (
                    <p id="refernceslinks">
                      <a target="_new" href={reference.referenceLink}>
                        <abbr style={{ textDecoration: "none",cursor: "pointer" }} title={reference.referenceLink}>{getDomainName(reference.referenceLink)}</abbr>
                      </a>
                    </p>
                  );
                })}
            </div>
          </section>
          <div
            style={{
              display: GetByBlog?.data?.getAuthorDtos?.length === 0 && "none",
            }}
          >
            <TeamMembers data={GetByBlog?.data?.getAuthorDtos} />
          </div>
          <button onClick={useParamsUrl} className="copy-link-btn">
            <i className="uil uil-copy"></i> {isCopy ? "Copied" : "Copy Link"}
          </button>

          <div className="line3">
            <div className="circle3">
              <img src={favico} alt="Icon" />
            </div>
          </div>
          <CardsForArticle />
          <Footer />
        </div>
      )}
    </>
  );
}

export default Article;
