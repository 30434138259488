import { httpClient } from "../Utils/HttpClient";


export const CreateBlog = (data) => {
    return httpClient.post('api/Blogs', data);
};
export const GetAllBlogs = () => {
    return httpClient.get('api/Blogs');
};
export const LastThreeBlogs = () => {
    return httpClient.get('api/Blogs/ThreeLastBlogs');
};
export const TotalPageCount = () => {
    return httpClient.get(`api/Blogs/TotalBlogPage?pageSize=${12}`);
}

export const GetAllBlogsSearch = (searchText, pageNumber) => {
    return httpClient.get(`api/Blogs/GetSearchBlogs?searchText=${searchText}&pageNumber=${pageNumber}`);
};
export const GetByBlogId = (id) => {
    return httpClient.get(`api/Blogs/GetById?Id=${id}`);
}

export const DeleteByBlog = (Id, AppUserId) => {
    return httpClient.delete(`api/Blogs/Remove?Id=${Id}&AppUserId=${AppUserId}`);
}