import React, { useState } from "react";
import "./searchbar.css";

function SearchBar({ onSearchChange }) {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearchInputChange = (value) => {
    setSearchTerm(value);
    onSearchChange(value);
  }

  return (
    <div className="input-box">
      <i className="uil uil-search"></i>
      <input
        type="text"
        placeholder="Search here..."
        value={searchTerm}
        onChange={(e) => onSearchInputChange(e.target.value)}
      />
      <button className="button">Search</button>
    </div>
  );
}

export default SearchBar;
