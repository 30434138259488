import React from "react";
import "./single-centered-cards.css";
import { LastThreeBlogs } from "../../Service/BlogService";
import { useQuery } from "react-query";
import favico from "../../Images/Bm.jpg";
import { Link } from "react-router-dom";
import BlogDetailsLoading from "../Loading/BlogDetailsLoading";

function CenteredCards() {

  const { data: ThreeBlog, isLoading } = useQuery(["GetAllBlog"], () => LastThreeBlogs());

  const toFormatDate = (rawDate) => {
    const date = new Date(rawDate);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };


  return (
    <div>

      <div className="line2">
        <div className="circle2">
          <img src={favico} alt="Icon" />
        </div>
      </div>

      {isLoading ? (<div style={{position: 'relative', height: "300px"}} >
        <BlogDetailsLoading />
      </div>) : (
        ThreeBlog?.data !== null &&
        ThreeBlog?.data?.slice(0, 1).map((card, index) => (
          <div key={index} id="cards" className="card-list centered-card-list">
            <Link to={`/article/${card.id}`} className="card-item">
              <img
                src={card.mainImageUrl}
                alt="Card Image"
              />
              <span className="developer">{card.blogAuthorName}</span>
              <h3>{card.title}</h3>
              <p>
                <i className="uil uil-schedule"></i>{toFormatDate(card.createdDate)}
              </p>
              <div className="arrow">
                <i className="uil uil-arrow-right"></i>
              </div>
            </Link>
            {/* More card items can be added here */}
          </div>
        ))
      )}
    </div>
  );
}
export default CenteredCards;
