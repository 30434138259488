import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/Navbar/navbar";
import Searchbar from "./components/Searchbar/searchbar";
import Cards from "./components/cards/cards";
import Footer from "./components/Footer/footer";
import Cookie from "./components/Cookies/cookie";
import TeamMembers from "./components/Team-members/team-member";
import { GetAllBlogsSearch, TotalPageCount } from "./Service/BlogService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Button } from "react-bootstrap";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import Loading from "./pages/Loading";
import BlogDetailsLoading from "./components/Loading/BlogDetailsLoading";

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const queryClient = useQueryClient();

  const onSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    queryClient.invalidateQueries(["GetAllBlog"]);
  };

  const { data: GetAllBlog, isLoading: loading } = useQuery(
    ["GetAllBlog", searchTerm, currentPage],
    () => GetAllBlogsSearch(searchTerm, currentPage)
  );

  const { data: BlogPageSize } = useQuery(["BlogPageSize"], () =>
    TotalPageCount()
  );

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < BlogPageSize?.data) {
      onPageChange(currentPage + 1);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2200);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="App">
          <Cookie />
          <Navbar />
          <Searchbar onSearchChange={onSearchChange} />
          {loading ? (
            <div style={{ marginBottom: "550px" }}>
              <BlogDetailsLoading />
            </div>
          ) : (
            <Cards data={GetAllBlog?.data} />
          )}
          <div
            style={{
              marginBottom: "60px",
              justifyContent: "center",
              alignItems: "center",
              display: BlogPageSize?.data === 1 ? "none" : "flex",
            }}
          >
            <Button
              style={{
                backgroundColor: "transparent",
                border: "none",
                visibility: currentPage === 1 && "hidden",
              }}
              onClick={handlePrevious}
              disabled={currentPage === 1}
            >
              <GrFormPrevious size={26} color="gray" />
            </Button>
            {BlogPageSize?.data !== null &&
              [...Array(BlogPageSize?.data).keys()].map((number) => {
                return (
                  <Button
                    key={number}
                    onClick={() => onPageChange(number + 1)}
                    style={{
                      backgroundColor: "black",
                      color: currentPage === number + 1 ? "white" : "white",
                      border: `2px solid ${currentPage === number + 1 ? "#e0e0e6" : "gray"
                        }`,
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {number + 1}
                  </Button>
                );
              })}
            <Button
              onClick={handleNext}
              style={{
                backgroundColor: "transparent",
                border: "none",
                visibility: currentPage === BlogPageSize?.data && "hidden",
              }}
              disabled={currentPage === BlogPageSize?.data}
            >
              <GrFormNext size={26} color="gray" />
            </Button>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
