import React, { useEffect, useRef } from "react";
import "../../src/Assets/Styles/aboutus-privacypolicy.css";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";
import CenteredCards from "../components/cards/single-centered-cards";
import Cookie from "../components/Cookies/cookie";

function PrivacyPolicy({ title }) {
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      document.title = `Boundmax - ${titleRef.current.textContent}`;
    }
  }, []);
  return (
    <div>
      <Cookie />
      <Navbar />
      <div className="container">
        <div className="header">
          <h1 ref={titleRef}>Privacy Policy</h1>
          <p>Last updated: May 7, 2024</p>
        </div>
        <div className="privacy-policy">
          <p>
            Welcome to <a style={{ fontWeight: "900", textDecoration: "none" }} href="/">Boundmax.com</a>  {" "}
            Your privacy is of paramount importance to us. This privacy policy outlines the type of data we collect and how it is used to enhance your experience on our website.
            <br />
            <br />
            <strong>Data Collection and Usage</strong><br /> At <a style={{ fontWeight: "900", textDecoration: "none" }} href="/">Boundmax.com</a>, we believe in simplicity and transparency. The only data we collect is the number of clicks each article receives. This helps us understand which topics engage our audience the most, allowing us to continue providing content that sparks interest and intellectual engagement.
            <br />
            <br />
            <strong>No Personal Data Collection</strong><br /> We do not collect any personal data from our visitors. There are no forms to fill out, no accounts to create, and no personal information to enter. You can explore our content freely and anonymously without the concern of personal data collection.
            <br />
            <br />
            <strong>Use of Cookies</strong><br /> Boundmax.com does not use cookies to track personal information. Our use of cookies is strictly limited to aggregating the total number of article clicks to better understand content popularity.
            <br />
            <br />
            <strong>Data Protection</strong><br /> Since we do not collect personal data, there is no risk of your personal information being exposed or misused. Our website is designed to respect your privacy and ensure your browsing experience remains private and secure.
            <br />
            <br />
            <strong>Changes to Our Privacy Policy</strong><br /> We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website.
            <br />
            <br />
            <strong>Contact Us</strong><br /> If you have any questions or suggestions about our privacy policy, please do not hesitate to contact us at <a style={{ fontWeight: "900", textDecoration: "underline" }}>boundmx@gmail.com</a>.
            <br />
            <br />
            Thank you for visiting <a style={{ fontWeight: "900", textDecoration: "none" }} href="/">Boundmax.com</a>, where new visions and unseen perspectives are always encouraged.
          </p>
        </div>
      </div>
      <CenteredCards />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
