import React from "react";
import "../../src/Assets/Styles/notfound.css";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";
import Cookie from "../components/Cookies/cookie";

function NotFoundPage() {
  return (
    <div>
      <Cookie />
      <Navbar />
      <div className="main-container">
        <h1>404</h1>
        <p>CONTENT NOT FOUND</p>
        <a href="/">Home page</a>
      </div>
      <Footer />
    </div>
  );
}

export default NotFoundPage;
