import React, { useState, useEffect } from "react";
import Styles from "./BlogDetailsLoading.module.css";
import Navbar from "../Navbar/navbar";

const BlogDetailsLoading = () => {

    const [mode, setMode] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const storedMode = localStorage.getItem("darkModeEnabled");
            const parsedMode = storedMode === "true";
            setMode(parsedMode);
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Navbar />
            <div className={mode ? Styles.darkLoader : Styles.whiteLoader}></div>
        </>
    );
};

export default BlogDetailsLoading;