export default [
    {
        "role": "Admin",
    },
    {
        "role": "SuperAdmin",
    },
];


