import React, { useEffect, useState } from "react";
import Styles from "./teamMembers.module.css";
import DefaultPersonImage from "./DefaultPersonImage.jpg";
import { FiLink2 } from "react-icons/fi";



function TeamSection({ data }) {

  const [mode, setMode] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const storedMode = localStorage.getItem("darkModeEnabled");
      const parsedMode = storedMode === "true";
      setMode(parsedMode);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  function formatName(fullname) {
    return fullname.length > 12 ? `${fullname.slice(0, 10)}...` : fullname;
  }

  return (
    <div className={Styles.teamSection} id={Styles.teamSection}>
      <div className={Styles.teamHeader}>Contributors and Authors</div>
      {data !== null &&
        data?.map((member, index) => (
          <div style={{ backgroundColor: mode === true ? "#09090a" : "white" }} key={index} className={Styles.teamMember}>
            <img
              src={
                member.profilImageUrl !== null
                  ? member.profilImageUrl
                  : DefaultPersonImage
              }
              alt={member.profilImageUrl}
            />
            <div className={Styles.teamMemberInfo}>
              <h3 style={{ color: mode ? "white" : "black", marginTop: "15px" }}>
                <abbr style={{ textDecoration: "none", cursor: "pointer" }} title={member.fullname}> {formatName(member.fullname)}</abbr>
                <a
                  href={member.contactLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a style={{ visibility: "hidden" }}>-</a>
                  <FiLink2 color={mode ? "white" : "black"} />
                </a>
              </h3>
              <p style={{ color: mode ? "white" : "black" }}>{member.position}</p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default TeamSection;
