import React, { useState, useEffect } from "react";
import "./cards.css";
import { Link } from "react-router-dom";
import { GetAllBlogs } from "../../Service/BlogService";
import { useQuery, useMutation, useQueryClient } from "react-query";

function Cards({ data }) {
  const toFormatDate = (rawDate) => {
    const date = new Date(rawDate);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <div>
      <div style={{ display: data?.length === 0 && "flex", justifyContent: data?.length === 0 && "center" }} id="cards" className="card-list">
        {data !== null &&
          data?.map((blog, index) => (
            <a key={index} href={`article/${blog.id}`} className="card-item">
              <img src={blog.mainImageUrl} alt="Card" />
              <span className={index % 2 === 0 ? "developer" : "designer"}>
                {blog.blogAuthorName}
              </span>
              <h3>{blog.title}</h3>
              <p>
                <i className="uil uil-schedule"></i>{" "}
                {toFormatDate(blog.createdDate)}
              </p>
              <div className="arrow">
                <i className="uil uil-arrow-right"></i>
              </div>
            </a>
          ))}
        {data?.length === 0 &&
          <div id="noblogfound" style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
            <h2>Article not found :{"("}</h2>
          </div>
        }
      </div>
    </div>
  );
}

export default Cards;
