import React from "react";
import DefaultPersonImage from "../Team-members/DefaultPersonImage.jpg";
import Styles from "../Team-members/teamMembers.module.css";

function Author({ authors }) {
    function formatName(fullname) {
        return fullname.length > 12 ? `${fullname.slice(0, 10)}...` : fullname;
    }
    return (
        <div className={Styles.teamSection} id={Styles.teamSection}>
            <div style={{ width: "240px" }} className={Styles.teamMember}>
                <img
                    src={
                        authors.profileImage !== ""
                            ? authors.profilImageUrl
                            : DefaultPersonImage
                    }
                    alt={authors.profileImage}
                />
                <div className={Styles.teamMemberInfo}>
                    <h3>
                        {formatName(authors.fullname)}
                        <a
                            href={authors.contactLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <a style={{ visibility: "hidden" }}>-</a>
                            <i className="uil uil-link-h"></i>
                        </a>
                    </h3>
                    <p>{authors.position}</p>
                </div>
            </div>
        </div>
    );
}

export default Author;
