import React from "react";
import "./cards-for-article-page.css";
import { LastThreeBlogs } from "../../Service/BlogService";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

function CardList() {
  const { data: ThreeBlog } = useQuery(["GetAllBlog"], () => LastThreeBlogs());

  const toFormatDate = (rawDate) => {
    const date = new Date(rawDate);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
      <div className="card-listmediaquery">
        <div id="cards" className="card-listforartcilepage">
          {ThreeBlog?.data !== null &&
            ThreeBlog?.data?.map((card, index) => (
              <Link to={`/article/${card.id}`} key={index} className="card-item">
                <img src={card.mainImageUrl} alt="Card Image" />
                <span id={index % 2 === 0 ? "developer" : "Designer"}>
                  {card.blogAuthorName}
                </span>
                <h3>{card.title}</h3>
                <p>
                  <i className="uil uil-schedule"></i>{" "}
                  {toFormatDate(card.createdDate)}
                </p>
                <div className="arrow">
                  <i className="uil uil-arrow-right"></i>
                </div>
              </Link>
            ))}
        </div>
      </div>
  );
}

export default CardList;
