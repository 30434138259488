import React, { useState, useEffect } from "react";
import "../../src/Assets/Styles/adminlogin.css";
import Navbar from "../components/Navbar/navbar";
import { useFormik } from "formik";
import { login } from "../Service/AuthService";
import { loginAction } from "../Redux/Slices/AuthSlice";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function AdminLogin() {
  const [passwordShown, setPasswordShown] = useState(true);
  const togglePasswordVisiblity = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  const notifyWarring = () => {
    toast.warning(`Invalid username or password.`, {});
  };


  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoginFormik = useFormik({
    initialValues: {
      UsernameOrEmail: "",
      password: "",
    },
    onSubmit: (values) => {
      LoginMutate(values);
    },
  });
  const { mutate: LoginMutate, isLoading } = useMutation(
    (values) => login(values),
    {
      onSuccess: (resp) => {
        dispatch(loginAction(resp));
        navigate("/adminpage");
      },

      onError: (error) => {
        notifyWarring();
        setLoginError("Invalid username or password.");
      },
    }
  );
  const handleInputChange = (e) => {
    LoginFormik.handleChange(e);
  };
  useEffect(() => {
    if (loginError) {
      const timer = setTimeout(() => {
        setLoginError(null);
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [loginError]);

  return (
    <div className="fullLoginForm">
      <Navbar />
      <div className="logincontainer">
        <div className="forms">
          <div className="form login">
            <span className="title">Login</span>
            <form id="loginForm" >
              <div className="input-field">
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  name="UsernameOrEmail"
                  onChange={handleInputChange}
                  required
                />
                <i className="uil uil-envelope icon"></i>
              </div>
              <div className="input-field">
                <input
                  type={passwordShown ? "password" : "text"}
                  id="password"
                  className="password"
                  placeholder="Enter your password"
                  required
                  onChange={handleInputChange}
                />
                <i className="uil uil-lock icon"></i>
                <i
                  className={`uil ${passwordShown === false ? "uil-eye" : "uil-eye-slash"
                    } showHidePw`}
                  onClick={togglePasswordVisiblity}
                ></i>
              </div>
              <div className="input-field button">
                <Button type="button"
                  style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => LoginFormik.handleSubmit()}>
                  {isLoading === true &&
                    <ClipLoader
                      color="blue"
                      loading={true}
                      size={25}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  }
                  {isLoading === false &&
                    <>Login</>
                  }
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AdminLogin;
