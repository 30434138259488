import React, { useState, useEffect } from "react";
import "./cookie.css";

function CookieConsent() {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true") {
      setShowConsent(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div id="cookieConsent">
      <span>
        We use cookies to ensure that we give you the best experience on our
        website. By clicking 'Accept', you agree to our
        <a href="privacypolicy">Privacy Policy</a>.
      </span>
      <button id="acceptCookie" className="accept-btn" onClick={handleAccept}>
        Accept
      </button>
    </div>
  );
}

export default CookieConsent;
