export default [
    {
      "name": "English",
      "code": "en"
    },
    {
      "name": "Spanish",
      "code": "es"
    },
    {
      "name": "French",
      "code": "fr"
    },
    {
      "name": "German",
      "code": "de"
    },
    {
      "name": "Chinese (Simplified)",
      "code": "zh-CN"
    },
    {
      "name": "Japanese",
      "code": "ja"
    },
    {
      "name": "Korean",
      "code": "ko"
    },
    {
      "name": "Arabic",
      "code": "ar"
    },
    {
      "name": "Russian",
      "code": "ru"
    },
    {
      "name": "Portuguese",
      "code": "pt"
    },
    {
      "name": "Italian",
      "code": "it"
    },
    {
      "name": "Hindi",
      "code": "hi"
    },
    {
      "name": "Bengali",
      "code": "bn"
    },
    {
      "name": "Dutch",
      "code": "nl"
    },
    {
      "name": "Swedish",
      "code": "sv"
    },
    {
      "name": "Greek",
      "code": "el"
    },
    {
      "name": "Turkish",
      "code": "tr"
    },
    {
      "name": "Polish",
      "code": "pl"
    },
    {
      "name": "Thai",
      "code": "th"
    },
    {
      "name": "Vietnamese",
      "code": "vi"
    },
    {
      "name": "Farsi (Persian)",
      "code": "fa"
    },
    {
      "name": "Indonesian",
      "code": "id"
    },
    {
      "name": "Hebrew",
      "code": "he"
    },
    {
      "name": "Czech",
      "code": "cs"
    },
    {
      "name": "Danish",
      "code": "da"
    }
  ];
