import React, { useState, useEffect } from "react";
import "../../src/Assets/Styles/adminpage.css";
import Navbar from "../components/Navbar/navbar";
import TextEditor from "../components/Editor/TextEditor";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Languages from "../components/Language/LanguageJson";
import AdminRole from "../components/Admin/AdminRole";
import Author from "../components/Author/Author";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import axios from "axios";
import { useFormik } from "formik";
import { CreateBlog } from "../Service/BlogService";
import { CreateUser } from "../Service/AuthService";
import { useMutation } from "react-query";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineLiveTv } from "react-icons/md";
import * as Yup from 'yup';
import ClipLoader from "react-spinners/ClipLoader";
import { logoutAction } from "../Redux/Slices/AuthSlice";
import { IoIosLogOut } from "react-icons/io";


const adminSchema = Yup.object().shape({
  Fullname: Yup.string()
    .required('Fullname is required'),
  Username: Yup.string()
    .matches(/[A-Z]/, 'Username must contain at least one uppercase letter')
    .required('Username is required'),
  Email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  Password: Yup.string()
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#\$%\^&\*]/, 'Password must contain at least one special character (!, @, #, etc.)')
    .required('Password is required'),
  UserRole: Yup.string()
    .required('User role is required')
});

//https://codepen.io/aybukeceylan/pen/abLNeox
function ArticleForm() {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogMainImage, setBlogMainImage] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [blogAuthorName, setBlogAuthorName] = useState("");
  const [blogText, setBlogText] = useState("");

  const [fullname, setFullname] = useState("");
  const [position, setPosition] = useState("");
  const [contactLink, setContactLink] = useState("");
  const [profilImageUrl, setProfileImage] = useState("");
  const [authors, setAuthors] = useState([]);

  const notifySuccess = (value) => {
    toast.success(`New ${value} Created successfully!`, {});
  };
  const notifyWarring = () => {
    toast.warning(`Please fill the inputs!`, {});
  };

  const handleAddAuthor = () => {
    const newAuthor = {
      fullname: fullname,
      position: position,
      contactLink: contactLink,
      profilImageUrl: profilImageUrl,
    };
    setAuthors([...authors, newAuthor]);
    // Reset input values
    setFullname("");
    setPosition("");
    setContactLink("");
    // setProfileImage("");
  };

  const handleDeleteAuthor = (index) => {
    setAuthors((prevAuthors) => {
      const newAuthors = [...prevAuthors];
      newAuthors.splice(index, 1);
      return newAuthors;
    });
  };

  const handBlogMainImage = async (event) => {
    const imageFile = event.target.files[0];

    const formData = new FormData();
    formData.append("ImageFile", imageFile);

    try {
      const response = await axios.post(
        "https://api.boundmax.com/api/Blogs/CreateMainImageUrl",
        formData
      );
      setBlogMainImage(response.data);
    } catch (error) {
      console.error("Save Image Error:", error);
    }
  };

  // onChange handler'ı tanımla
  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleAuthorImage = async (event) => {
    const imageFile = event.target.files[0];

    const formData = new FormData();
    formData.append("ImageFile", imageFile);

    try {
      const response = await axios.post(
        "https://api.boundmax.com/api/Blogs/CreateMainImageUrl",
        formData
      );
      setProfileImage(response.data);
    } catch (error) {
      console.error("Save Image Error:", error);
    }
  };

  const [ReferenceLink, setReferenceLink] = useState("");
  const [GetReferenceDtos, setGetReferenceDtos] = useState([]);

  const handleAddReverence = () => {
    const newReference = {
      ReferenceLink: ReferenceLink,
    };
    setGetReferenceDtos([...GetReferenceDtos, newReference]);
    // Reset input values
    setReferenceLink("");
  };

  const handleDeleteReference = (index) => {
    setGetReferenceDtos((prevReference) => {
      const newReference = [...prevReference];
      newReference.splice(index, 1);
      return newReference;
    });
  };

  const { token } = useSelector((x) => x.auth);
  const decodedToken = token ? jwtDecode(token) : null;
  //change
  const adminId = decodedToken
    ? decodedToken[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ]
    : null;

  const roles = decodedToken
    ? decodedToken[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ]
    : [];

  const [isLoadingBlog, setIsLoadingBlog] = useState(false);

  const CreateNewBlog = useFormik({
    initialValues: {
      AdminId: adminId,
      Title: blogTitle ? blogTitle : "",
      SourceLanguage: selectedLanguage ? selectedLanguage : "",
      MainImageUrl: blogMainImage ? blogMainImage : "",
      BlogAuthorName: blogAuthorName ? blogAuthorName : "",
      Text: blogText ? blogText : "",
      CreateAuthorDtos: authors ? authors : null,
      CreateReferenceDtos: GetReferenceDtos ? GetReferenceDtos : null,
    },
    onSubmit: (values) => {
      NewBlogMutate(values);
      setIsLoadingBlog(true);
    },
    // validationSchema: reservationScheme,
  });

  useEffect(() => {
    CreateNewBlog.setFieldValue("Title", blogTitle ? blogTitle : null);
    CreateNewBlog.setFieldValue(
      "SourceLanguage",
      selectedLanguage ? selectedLanguage : null
    );
    CreateNewBlog.setFieldValue(
      "MainImageUrl",
      blogMainImage ? blogMainImage : null
    );
    CreateNewBlog.setFieldValue(
      "BlogAuthorName",
      blogAuthorName ? blogAuthorName : null
    );
    CreateNewBlog.setFieldValue("Text", blogText ? blogText : "");
    CreateNewBlog.setFieldValue(
      "CreateAuthorDtos",
      authors.length > 0 ? authors : []
    );
    CreateNewBlog.setFieldValue(
      "CreateReferenceDtos",
      GetReferenceDtos.length > 0 ? GetReferenceDtos : []
    );
  }, [authors, GetReferenceDtos, blogTitle, blogMainImage, blogAuthorName, selectedLanguage]);

  const { mutate: NewBlogMutate } = useMutation(
    (values) => CreateBlog(values),
    {
      onSuccess: (resp) => {
        setIsLoadingBlog(false);
        setBlogTitle("");
        setBlogMainImage("");
        setSelectedLanguage("");
        setBlogAuthorName("");
        setBlogText("");
        setAuthors([]);
        setGetReferenceDtos([]);
        notifySuccess("Blog");
      },
      onError: (error) => {
        setIsLoadingBlog(false);
        notifyWarring();
      },
    }
  );

  const onChangeTextEditoValue = (value) => {
    setBlogText(value);
  };

  const navigate = useNavigate();
  const [createOption, setCreateOption] = useState(false);
  const [adminRole, setAdminRole] = useState("Admin");

  const handleAdminRoleChange = (event) => {
    setAdminRole(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      Fullname: "",
      Username: "",
      Email: "",
      Password: "",
      UserRole: adminRole ? adminRole : adminRole,
      SuperAdminId: roles === "SuperAdmin" ? adminId : "",
    },
    validationSchema: adminSchema,
    onSubmit: async (values) => {
      NewUserCreate(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("UserRole", adminRole ? adminRole : "Admin");
  }, [adminRole]);

  const { mutate: NewUserCreate, isLoading } = useMutation(
    (values) => CreateUser(values),
    {
      onSuccess: (resp) => {
        notifySuccess("Admin");
      },
      onError: (error) => {
        notifyWarring();
      },
    }
  );

  useEffect(() => {
    localStorage.setItem('blogTitle', blogTitle);
    localStorage.setItem('blogMainImage', blogMainImage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
    localStorage.setItem('blogText', blogText);
    localStorage.setItem('GetReferenceDtos', JSON.stringify(GetReferenceDtos));
    localStorage.setItem('authors', JSON.stringify(authors));
  }, [blogTitle, blogMainImage, selectedLanguage, blogText, GetReferenceDtos, authors])

  const logOutFunction = () => {
    localStorage.clear();
    navigate("/")
  }

  return (
    <div>
      <Navbar adminPage={true} />
      <div className="adminPageContainer">
        {roles === "SuperAdmin" && (
          <div className="AdminOptions" style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={() => logOutFunction()}
              style={{
                backgroundColor: "transparent",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                fontFamily: "revert",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              Logout <IoIosLogOut />
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                fontFamily: "revert",
                fontSize: "20px",
                marginRight: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fcba03",
                borderColor: "#fcba03"
              }}
            >
              <Link target="_blank" to="/LiveBlogDetails" style={{ textDecoration: "none", color: "inherit" }}>
                Live Preview <MdOutlineLiveTv style={{ marginLeft: "5px" }} color="#fcba03" />
              </Link>
            </button>
            <button
              onClick={() => navigate("/AdminAllBlogs")}
              style={{
                backgroundColor: "transparent",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                fontFamily: "revert",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              All Blogs
            </button>
            <button
              onClick={() => setCreateOption((pres) => !pres)}
              style={{
                backgroundColor: "transparent",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                fontFamily: "revert",
                fontSize: "20px",
              }}
            >
              {createOption === false ? "Create Admin" : "Create Blog"}
            </button>
          </div>
        )}
        <h1>
          {createOption === false ? "Create a New Article" : "Create Admin"}
        </h1>
        <Form>
          {createOption === false ? (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Blog Title</Form.Label>
                <Form.Control
                  value={blogTitle}
                  onChange={(e) => setBlogTitle(e.target.value)}
                  type="text"
                  placeholder="Enter Title"
                />
                <Form.Text className="text-muted">
                  Add a title to the blog
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Blog Author Name</Form.Label>
                <Form.Control
                  value={blogAuthorName}
                  onChange={(e) => setBlogAuthorName(e.target.value)}
                  type="text"
                  placeholder="Enter Blog Author Name"
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Blog Main Image</Form.Label>
                <Form.Control onChange={handBlogMainImage} type="file" />
              </Form.Group>

              <Form.Select value={selectedLanguage} onChange={handleChange}>
                <option>Default select</option>
                {Languages.map((language) => (
                  <option key={language.code} value={language.name}>
                    {language.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Group controlId="formFile" className="mb-3 mt-3">
                <Form.Label>Blog Description Text</Form.Label>
                <TextEditor onChangeTextEditoValue={onChangeTextEditoValue} />
              </Form.Group>

              <span
                style={{
                  display: "block",
                  marginTop: "50px",
                  border: "1px solid gray",
                  maxWidth: "96%",
                  padding: "20px",
                  minHeight: "240px",
                }}
              >
                <h5>Add Reference</h5>

                <Form.Group className="mb-3" controlId="formBasicReferenceLink">
                  <Form.Label>Reference Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Reference Link"
                    value={ReferenceLink}
                    onChange={(e) => setReferenceLink(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  style={{ marginLeft: "80%" }}
                  type="button"
                  onClick={handleAddReverence}
                >
                  Add Reference
                </Button>

                <Container>
                  <Row>
                    {GetReferenceDtos.map((reference, index) => (
                      <Col
                        key={index}
                        style={{
                          borderBottom: "0.4px solid gray",
                          padding: "10px",
                          marginTop: "4px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        sm={12}
                      >
                        {index + 1}
                        {")"} {reference.ReferenceLink.length > 46 ? reference.ReferenceLink.slice(0, 46) + "..." : reference.ReferenceLink}

                        <Button
                          style={{ height: "auto" }}
                          variant="danger"
                          type="button"
                          onClick={() => handleDeleteReference(index)}
                        >
                          Delete
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </span>

              <span
                style={{
                  display: "block",
                  marginBottom: "20px",
                  marginTop: "50px",
                  border: "1px solid gray",
                  maxWidth: "96%",
                  padding: "20px",
                  minHeight: "240px",
                }}
              >
                <h5>Add Authors</h5>

                <Form.Group className="mb-3" controlId="formBasicFullname">
                  <Form.Label>Fullname</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Fullname"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Profile Image</Form.Label>
                  <Form.Control type="file" onChange={handleAuthorImage} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPosition">
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicContactLink">
                  <Form.Label>Author Contact Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Author Contact Link"
                    value={contactLink}
                    onChange={(e) => setContactLink(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  style={{ marginLeft: "80%" }}
                  type="button"
                  onClick={handleAddAuthor}
                >
                  Add Author
                </Button>

                <Container>
                  <Row>
                    {authors.map((author, index) => (
                      <Col key={index} style={{ display: "flex", marginTop: "10px" }} sm={6}>
                        <Author authors={author} />
                        <Button
                          style={{ height: "66px", marginTop: "22px" }}
                          variant="danger"
                          type="button"
                          onClick={() => handleDeleteAuthor(index)}
                        >
                          Delete
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </span>

              <Button
                onClick={() => CreateNewBlog.handleSubmit()}
                variant="success"
                type="button"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                {isLoadingBlog === true &&
                  <ClipLoader
                    color="#f15e5e"
                    loading={true}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                }
                {isLoadingBlog === false &&
                  <>
                    Create Blog
                  </>
                }
              </Button>
            </>
          ) : (
            <>
              <Form className="mt-3">
                <Form.Group className="mb-1" controlId="create-workspace-name">
                  <Form.Control
                    className="mb-2 p-3"
                    type="text"
                    placeholder="Fullname"
                    name="Fullname"
                    value={formik.values.Fullname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.Fullname && !!formik.errors.Fullname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Fullname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1" controlId="create-username">
                  <Form.Control
                    className="mb-2 p-3"
                    type="text"
                    placeholder="Username"
                    name="Username"
                    value={formik.values.Username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.Username && !!formik.errors.Username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1" controlId="create-email">
                  <Form.Control
                    className="mb-2 p-3"
                    type="email"
                    placeholder="Email"
                    name="Email"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.Email && !!formik.errors.Email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1" controlId="create-password">
                  <Form.Control
                    className="mb-2 p-3"
                    type="password"
                    placeholder="Password"
                    name="Password"
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.Password && !!formik.errors.Password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1" controlId="create-workspace-type">
                  <Form.Select value={adminRole} onChange={handleAdminRoleChange}>
                    <option>Default select</option>
                    {AdminRole.map((roles) => (
                      <option value={roles.role}>{roles.role}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <span className="w-100">
                  <Button
                    type="button"
                    onClick={() => formik.handleSubmit()}
                    className="container create-workspace-submit w-100 m-0"
                    variant="success"
                    size="lg"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    {isLoading === true &&
                      <ClipLoader
                        color="#f15e5e"
                        loading={true}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    }
                    {isLoading === false &&
                      <>
                        Create Admin
                      </>
                    }
                  </Button>
                </span>
              </Form>
            </>
          )}
        </Form>
        <ToastContainer />
        {/* <input type="checkbox" id="show-deleted" />
      <label htmlFor="show-deleted">Show deleted articles</label> */}
      </div>
    </div>
  );
}

export default ArticleForm;
