import { httpClient } from "../Utils/HttpClient";

export const login = (data) => {
    return httpClient.post('api/Auth/Login', data);
};

export const CreateUser = (data) => {
    try {
        return httpClient.post('api/Auth/CreateUser', data);
    }
    catch (error) {
        console.error(error);
    }
};

// export const checkIsAdmin = (id) => {
//     return httpClient.get(`api/AppUser/CheckAdmin?AdminId=${id}`);
// };