import React, { useEffect, useRef } from "react";
import "../../src/Assets/Styles/aboutus-privacypolicy.css";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";
import CenteredCards from "../components/cards/single-centered-cards";
import Cookie from "../components/Cookies/cookie";

function AboutUs({ title }) {
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      document.title = `Boundmax - ${titleRef.current.textContent}`;
    }
  }, []);
  return (
    <div>
      <Cookie />
      <Navbar />
      <div className="container">
        <div className="header">
          <h1 ref={titleRef}>About Us</h1>
          <p>Last updated: May 7, 2024</p>
        </div>
        <div className="privacy-policy">
          <p>
            At Boundmax, we are driven by a simple yet powerful mission: to inspire thought and provoke intellectual engagement. We understand that in today’s fast-paced digital world, critical thinking is often overshadowed by the noise of relentless media consumption. That’s why we’ve dedicated ourselves to providing a platform that not only presents diverse viewpoints on a multitude of topics but also encourages our readers to think deeply about them.
            <br /><br />
            Our articles are designed to make you think, presenting fresh perspectives that challenge common views. We carry this approach over to our social media, where we engage in deep discussions about these topics. Connect with us for engaging content and lively debates across our platforms on YouTube, Twitch, TikTok, Instagram, and X.
            <br /><br />
            Boundmax was brought to life through the visionary efforts of Ali Nuri and Ulvi Kerimov, whose dedication has made this platform a reality. Together, we strive to break the mold of conventional thinking and empower our audience to question, reflect, and understand the world around them in new and meaningful ways.
            <br /><br />
            For inquiries, suggestions, or contributions, don’t hesitate to reach out to us at boundmx@gmail.com. New Vision, Unseen Perspectives Boundmax {" "}
            <strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:boundmx@gmail.com"
              >
                boundmx@gmail.com
              </a>
            </strong>
            .
          </p>
        </div>
      </div>
      <CenteredCards />
      <Footer />
    </div>
  );
}

export default AboutUs;
