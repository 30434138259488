import React from 'react'
import Styles from "./Loading.module.css";


const Loading = () => {


    return (
        <div className={Styles.Main}>
            <div className={Styles.loadingContainer}>
                <div className={Styles.loadingText}>
                    <span>B</span>
                    <span>O</span>
                    <span>U</span>
                    <span>N</span>
                    <span>D</span>
                    <span>M</span>
                    <span>A</span>
                    <span>X</span>
                </div>
            </div>
        </div>
    )
}

export default Loading