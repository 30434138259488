import React, { useState, useEffect } from "react";
import "./navbar.css";

const darkModeStorageKey = "darkModeEnabled";

function Navbar({adminPage}) {
  const [isSticky, setIsSticky] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    () => JSON.parse(localStorage.getItem(darkModeStorageKey)) || false
  );

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
    localStorage.setItem(darkModeStorageKey, isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <nav className={isSticky ? "sticky" : ""}>
      <div className="nav-content">
        <div className="logo">
          <a href="/">Boundmax</a>

          <i
            id="dark-mode-toggle"
            className={`uil ${isDarkMode ? "uil-sun" : "uil-moon"}`}
            onClick={toggleDarkMode}
            style={{display: adminPage && 'none'}}
          ></i>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
